.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  height: 90px;
}

.App-logo, .Title {
  margin: 0;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  height: 120px;
  display: flex;
  flex-direction: row; /* Aligns children (logo and title) in a row */
  align-items: center; /* Vertically centers the children in the container */
  justify-content: center; /* Horizontally centers the children */
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Title {
  font-size: 2em;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: bold;
}

/* Default mobile styles */
.Pastel {
  width: 100%; /* Make the image stretch to the edges on mobile */
  height: auto; /* Maintain the image's aspect ratio */
}

/* Desktop styles */
@media (min-width: 512px) { /* Adjust this breakpoint as needed */
  .Pastel {
    max-width: 512px; /* Maximum width on desktop */
    height: auto; /* Maintain the image's aspect ratio */
    margin: 0 auto; /* Center the image if the screen is wider than 512px */
  }
}

.Get-app {
  font-family:Arial, Helvetica, sans-serif;
  font-size: 1.5em;
  margin: 1em;
  padding: 1em;
  /* background-color: #f0f0f0; */
  /* border: 1px solid #d3d3d3; */
  /* border-radius: 5px; */
}

.Step-instructions {
  font-family:Arial, Helvetica, sans-serif;
  font-size: 1.5em;
  /* background-color: #f0f0f0; */
  /* border: 1px solid #d3d3d3; */
  /* border-radius: 5px; */
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0; /* Add some margin to separate it from other elements, adjust as needed */
}

.Step-text {
  font-family:Arial, Helvetica, sans-serif;
  font-size: 1.5em;
  font-weight: bold;
  margin: 1em;
} 

.URL-scheme-link {
  font-family:Arial, Helvetica, sans-serif;
  font-size: 1.5em;
  color: #037392;
  text-decoration: underline;

}