
.Store-Button {
  background: none; /* Removes any default styling */
  border: none; /* Removes the border */
  cursor: pointer; /* Changes the cursor to pointer on hover */
  padding: 0; /* Adjust padding as needed*/
  height: 100px;
  width: auto;
}
  
.App-Store-Image {
    height: 55px;
    width: auto;
  }

  .Play-Store-Image {
    height: 82px;
    width: auto;
    translate: 13px 12px;
  }